import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, Button, IconButton, Badge, TablePagination, Checkbox, Grid, Alert } from '@mui/material';
import MarkUnreadChatAltIcon from '@mui/icons-material/MarkUnreadChatAlt';

import axios from 'axios';
import { API_PATH } from '../../../App';
import { getAxiosConfig } from '../../../helpers/AuthHelper';
import WarningIcon from '@mui/icons-material/Warning';
import NotificationModal from './NotificationModal';
import ExpireModal from './ExpireModal';


interface Notification {
    id: string;
    message: string;
    infos: string[];
}

interface PaginationState {
    page: number;
    rowsPerPage: number;
    totalItemCount: number;
    pageableData: { pageNumber: number; pageSize: number } | null;
}

interface MachineExpireData {
    workerId: string;
    endDate: string;
    id: number;
}

export const CustomNotifications: React.FC = () => {
    const [open, setOpen] = useState(false);
    const [count, setCount] = useState(0);
    const [notifications, setNotifications] = useState<Notification[]>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        page: 0,
        rowsPerPage: 10,
        totalItemCount: 0,
        pageableData: null,
    });
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);

    const [machineExpireData, setMachineExpireData] = useState<MachineExpireData[]>([]);
    const [openExpireModal, setOpenExpireModal] = useState(false);

    var timer: any = null;

    useEffect(() => {
        checkForNotification();
        fetchmachineExpireData()
        timer = setTimeout(() => {
            checkForNotification();
        }, 60000);
        return () => {
            clearTimeout(timer);
        };
    }, []);

    useEffect(() => {
        if (open) {
            fetchNotificationData();
        }
    }, [open, page, rowsPerPage]);

    function checkForNotification() {
        axios.get(API_PATH + '/alerts/check', getAxiosConfig()).then((response) => {
            setCount(response.data);
        });
    }

    function fetchNotificationData() {
        setLoading(true);
        axios
            .get(
                `${API_PATH}/alerts/list?page=${page}&limit=${rowsPerPage}&read=${notifications.map((notification) => notification.id).join(',')}`,
                getAxiosConfig()
            )
            .then((res) => {
                setNotifications(res.data?.content || []);
                setPagination((prevState) => ({
                    ...prevState,
                    totalItemCount: res.data.totalElements,
                    pageableData: {
                        pageNumber: res.data.pageable.pageNumber,
                        pageSize: res.data.pageable.pageSize,
                    },
                }));
                checkForNotification()
            })
            .finally(() => {
                setLoading(false);
            });
    }

    function fetchmachineExpireData() {
        axios
            .get(`${API_PATH}/machines/notification`, getAxiosConfig())
            .then((res) => {
                const formattedData = res.data.map((item: any) => ({
                    workerId: item.workerId,
                    endDate: item.invoiceItem.endDate,
                    id: item.id,
                }));
                setMachineExpireData(formattedData);

            })
            .catch((error) => {
                console.error("Error fetching machine expire data:", error);
            });
    }


    const handleNotification = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPagination((prevState) => ({ ...prevState, page: newPage }));
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleMarkAsRead = () => {
        fetchNotificationData();
    };

  

    return (
        <>
            {(count > 0) && (
                <IconButton
                    aria-label="add"
                    onClick={handleNotification}
                    sx={{
                        position: 'fixed',
                        bottom: 40,
                        left: 40,
                        width: 40,
                        height: 40,
                        color: '#ff0000',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 2000,
                        '&:hover': {
                            color: '#ff0009',
                        },
                    }}
                >
                    <Badge badgeContent={count} color="primary">
                        <MarkUnreadChatAltIcon fontSize="large" />
                    </Badge>
                </IconButton>
            )}
            {machineExpireData.length > 0 && (
                <IconButton
                    aria-label="machine-expire"
                    onClick={() => setOpenExpireModal(true)}
                    sx={{
                        position: 'fixed',
                        bottom: 40,
                        left: 100,
                        width: 40,
                        height: 40,
                        color: '#ff0000',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 2000,
                        '&:hover': {
                            color: '#ff0009',
                        },
                    }}
                >
                    <Badge badgeContent={machineExpireData.length} color="secondary">
                        <WarningIcon fontSize='large' />
                    </Badge>
                </IconButton>
            )}

            <NotificationModal
                open={open}
                onClose={() => setOpen(false)}
                notifications={notifications}
                pagination={pagination}
                loading={loading}
                rowsPerPage={rowsPerPage}
                page={page}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
                handleMarkAsRead={handleMarkAsRead}
            />

            <ExpireModal
                openExpireModal={openExpireModal}
                setOpenExpireModal={setOpenExpireModal}
                machineExpireData={machineExpireData}
            />

        </>
    );
};