import React from "react";
import { Modal, Box, Typography, Grid, Button, useTheme } from "@mui/material";

export interface MachineExpireData {
    id: number;
    workerId: string;
    endDate: string;
}

export interface ExpireModalProps {
    openExpireModal: boolean;
    setOpenExpireModal: (open: boolean) => void;
    machineExpireData: MachineExpireData[];
}

const formatDate = (dateString: string): string => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
};

const ExpireModal: React.FC<ExpireModalProps> = ({ openExpireModal, setOpenExpireModal, machineExpireData }) => {
    const theme = useTheme();

    return (
        <Modal open={openExpireModal} onClose={() => setOpenExpireModal(false)}>
            <Box
                sx={{
                    width: { xs: "90%", sm: "70%", md: "50%" },
                    margin: "auto",
                    mt: 10,
                    bgcolor: theme.palette.mode === "dark" ? "grey.900" : "background.paper",
                    p: 4,
                    borderRadius: 2,
                    boxShadow: 6,
                    color: theme.palette.text.primary,
                    maxHeight: "80vh",
                    overflowY: "auto",
                }}
            >
                <Typography
                    variant="h5"
                    fontWeight="bold"
                    gutterBottom
                    color={theme.palette.mode === "dark" ? "secondary" : "primary"}
                >
                    Machines Expiring Soon
                </Typography>
                <Grid container spacing={2}>
                    {machineExpireData.length > 0 ? (
                        machineExpireData.map((machine) => (
                            <Grid item xs={12} key={machine.id}>
                                <Box
                                    sx={{
                                        p: 3,
                                        bgcolor: theme.palette.mode === "dark" ? "grey.800" : "grey.100",
                                        borderRadius: 2,
                                        boxShadow: 2,
                                        transition: "0.3s",
                                        "&:hover": { boxShadow: 4 },
                                        borderLeft: `5px solid ${theme.palette.error.main}`,
                                    }}
                                >
                                    <Typography variant="body1">
                                        <strong>Worker ID:</strong> {machine.workerId}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>End Date:</strong> {formatDate(machine.endDate)}
                                    </Typography>
                                    <Typography variant="body1">
                                        <strong>Invoice ID:</strong> {machine.id}
                                    </Typography>
                                </Box>
                            </Grid>
                        ))
                    ) : (
                        <Typography
                            variant="body1"
                            color="text.secondary"
                            sx={{ textAlign: "center", mt: 2 }}
                        >
                            No expiring machines found.
                        </Typography>
                    )}
                </Grid>
                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
                    <Button
                        onClick={() => setOpenExpireModal(false)}
                        variant="contained"
                        color="error"
                        sx={{
                            bgcolor: theme.palette.mode === "dark" ? "error.dark" : "error.main",
                            "&:hover": {
                                bgcolor: theme.palette.mode === "dark" ? "error.light" : "error.dark",
                            },
                        }}
                    >
                        Close
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default ExpireModal;
