import { useEffect, useMemo, useState } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
    type MRT_ColumnDef,
    type MRT_ColumnFiltersState,
    type MRT_PaginationState,
    type MRT_SortingState,
} from 'material-react-table';
import { Box, Stack } from '@mui/material';

type Order = {
    id: string;
    date: string;
    orderId: string;
    groupId: string;
    username: string;
    port: string;
    hashRate: number;
    totalExpense: number;
    amount:number;
};

const OrderTable = ({ row, loader }: { row: Order[], loader: boolean }) => {
    const [data, setData] = useState<Order[]>([]);
    const [isError, setIsError] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);

    const [columnFilters, setColumnFilters] = useState<MRT_ColumnFiltersState>([]);
    const [globalFilter, setGlobalFilter] = useState('');
    const [sorting, setSorting] = useState<MRT_SortingState>([]);
    const [pagination, setPagination] = useState<MRT_PaginationState>({
        pageIndex: 0,
        pageSize: 100,
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = {
                    data: row,
                    meta: {
                        totalRowCount: row.length,
                    },
                };
                setData(response.data);
                setRowCount(response.meta.totalRowCount);
            } catch (error) {
                setIsError(true);
                console.error(error);
                return;
            }
            setIsError(false);
            setIsRefetching(false);
        };

        fetchData();
    }, [row, columnFilters, globalFilter, pagination.pageIndex, pagination.pageSize, sorting]);

    const totalExpense = useMemo(
        () => row.reduce((acc, curr) => acc + (curr.amount || 0), 0),
        [row],
    );

    const columns = useMemo<MRT_ColumnDef<Order>[]>(
        () => [
            {
                accessorKey: 'date',
                header: 'Date',
                Cell: ({ row }) => `${((row.original.date).split('T')[0].split("-").reverse().join("/"))}`,
            },
            {
                accessorKey: 'orderId',
                header: 'Order ID',
                Cell: ({ row }) => `${(row.original.orderId.split('-')[0])}`,
            },
            {
                accessorKey: 'username',
                header: 'User Name',
            },
            {
                accessorKey: 'port',
                header: 'Port',
            },
            {
                accessorKey: 'hashRate',
                header: 'Hash Rate',
                Cell: ({ row }) => `${(row.original.hashRate).toFixed(2)}`,
            },
            // {
            //     accessorKey: 'rejectionRate',
            //     header: 'Rejection Rate',
            //     Cell: ({ row }) => `${(row.original.rejectionRate).toFixed(2)}`,
            // },
            {
                accessorKey: 'amount',
                header: 'Total Expense',
                Cell: ({ row }) => `${(row.original.amount).toFixed(8)}`,
            },
        ],
        [totalExpense]
    );

    const table = useMaterialReactTable({
        columns,
        data,
        enableRowSelection: false,
        getRowId: (row) => row.id,
        initialState: { showColumnFilters: false },
        manualFiltering: false,
        manualPagination: false,
        manualSorting: false,
        muiToolbarAlertBannerProps: isError
            ? {
                color: 'error',
                children: 'Error loading data',
            }
            : undefined,
        onColumnFiltersChange: setColumnFilters,
        onGlobalFilterChange: setGlobalFilter,
        onPaginationChange: setPagination,
        onSortingChange: setSorting,
        rowCount,
        state: {
            columnFilters,
            globalFilter,
            isLoading: loader,
            pagination,
            showAlertBanner: isError,
            showProgressBars: isRefetching,
            sorting,
        },
        muiPaginationProps: {
            rowsPerPageOptions: [100, 200, 500, 1000],
            showFirstButton: false,
            showLastButton: false,
        },
    });

    return (
        <>
            <Box sx={{display:'flex' ,mb:3}}>
                <Stack sx={{ mr:4}}>
                    <Box sx={{ color: 'gray', fontSize: '0.8rem' }}>Cost to Segments</Box>
                    <Box color="warning.main" sx={{ fontWeight: 'bold' }}>
                        {totalExpense?.toLocaleString?.('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 8,
                        })}
                    </Box>
                </Stack>
            </Box>
            <MaterialReactTable table={table} />
        </>

    )
};

export default OrderTable;
