import React, { useState, useEffect, SetStateAction, Dispatch } from 'react';
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Table, TableBody, TableCell, TableHead, TableRow, Button, TextField, ButtonGroup, CircularProgress, Snackbar, Alert } from '@mui/material';
import axios from 'axios';
import { MachineInfo } from './types';
import { API_PATH } from '../../../App';
import { getAxiosConfig } from '../../../helpers/AuthHelper';
import SingleSelectChip2 from '../GlobalComponents/SingleSelectChip2';
import MachineOnOffButtonGroup from './MachineOnOffButtonGroup';

interface ConfigModalProps {
    open: boolean;
    onClose: () => void;
    data: MachineInfo[];
    trigger: boolean;
    setTrigger: Dispatch<SetStateAction<boolean>>;

}

export interface MachineConfigRowState {
    id: number;
    modelName: string;
    hashVendor: number | null;
    targetPool: number | null;
    workerId: string | null;
    onOff: 'ON' | 'OFF' | 'NEW';
    data: MachineInfo;
}

const ConfigModal: React.FC<ConfigModalProps> = ({ open, onClose, data = [], trigger, setTrigger }) => {
    const [rowsState, setRowsState] = useState<MachineConfigRowState[]>([]);
    const [allHashVendor, setAllHashVendor] = useState<number | null>(null);
    const [allTargetPool, setAllTargetPool] = useState<number | null>(null);
    const [manualWorkerId, setManualWorkerId] = useState<string | null>(null);
    const [allOnOff, setAllOnOff] = useState<'ON' | 'OFF' | 'NEW' | null>(null);

    const [hashVendorList, setHashVendorList] = useState<{ name: string }[]>([]);
    const [targetPoolList, setTargetPoolList] = useState<string[]>([]);

    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [alertMessage, setAlertMessage] = useState<string>('');

    useEffect(() => {
        if (data.length > 0) {
            setRowsState(data.map(item => ({
                id: item.id,
                modelName: item.modelName,
                hashVendor: item.hashVendor ? hashVendorList.findIndex(vendor => vendor.name === item.hashVendor) ?? null : allHashVendor,
                targetPool: item.targetPool ? targetPoolList.indexOf(item.targetPool) : allTargetPool ?? null,
                workerId: item.workerId || null,
                onOff: item.machineStatus as 'ON' | 'OFF' | 'NEW',
                data: item,
            })));
        }
        setAlertMessage('')
    }, [data]);

    useEffect(() => {
        const fetchLists = () => {
            Promise.all([
                axios.get(API_PATH + '/machines/hash_vendor', getAxiosConfig()),
                axios.get(API_PATH + '/machines/target_pools', getAxiosConfig())
            ])
                .then(([hashVendorResponse, targetPoolResponse]) => {
                    setHashVendorList(hashVendorResponse.data);
                    setTargetPoolList(targetPoolResponse.data);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
        };

        fetchLists();
    }, []);

    const autofillWorkerIds = (firstId: string) => {
        const match = firstId.match(/^(.*?)(\d*)$/);
        if (!match || match[2] === '') {
            const clearedWorkerIds = rowsState.map(row => ({ ...row, workerId: firstId || '' }));
            setRowsState(clearedWorkerIds);
            return;
        }
        const prefix = match[1];
        let startNumber = parseInt(match[2], 10);
        const numberLength = match[2].length;
        if (isNaN(startNumber)) {
            const resetWorkerIds = rowsState.map(row => ({ ...row, workerId: firstId }));
            setRowsState(resetWorkerIds);
            return;
        }
        const newWorkerIds = rowsState.map((row, index) => {
            const newWorkerId = `${prefix}${String(startNumber + index).padStart(numberLength, '0')}`;
            return { ...row, workerId: newWorkerId };
        });

        setRowsState(newWorkerIds);
    };

    const handleAllChange = (field: 'hashVendor' | 'targetPool', value: number | null) => {
        setRowsState(prevState => {
            return prevState.map(row => {
                const updatedRow = { ...row, [field]: value };
                if (field === 'hashVendor' && value === 0) {
                    updatedRow.targetPool = 1;
                } else if (field === 'hashVendor' && value === 1) {
                    updatedRow.targetPool = 2;
                } else if (field === 'hashVendor' && value === null) {
                    updatedRow.targetPool = null;
                }
                return updatedRow;
            });
        });

        if (field === 'hashVendor') {
            setAllHashVendor(value);
            if (value === 0) {
                setAllTargetPool(1);
            }
            else if (value === 1) {
                setAllTargetPool(2)
            }
        } else if (field === 'targetPool') {
            setAllTargetPool(value);
        }
    };

    const handleRowChange = (index: number, field: keyof MachineConfigRowState, value: number | string | 'ON' | 'OFF' | 'NEW' | null) => {
        if (field === 'hashVendor' && allHashVendor !== null) {
            value = allHashVendor;
        }
        // else if (field === 'targetPool' && allTargetPool !== null) {
        //     value = allTargetPool;
        // }
        let updatedRow = { ...rowsState[index], [field]: value };

        if (field === 'hashVendor' && value === 0) {
            updatedRow.targetPool = 1;
            setRowsState(prevState =>
                prevState.map((row, i) => (i === index ? updatedRow : row))
            );
        }
        else if (field === 'hashVendor' && value === 1) {
            updatedRow.targetPool = 2;
            setRowsState(prevState =>
                prevState.map((row, i) => (i === index ? updatedRow : row))
            );
        }
        else if (field === 'hashVendor' && value === null) {
            updatedRow.targetPool = null;
            setRowsState(prevState =>
                prevState.map((row, i) => (i === index ? updatedRow : row))
            );
        }

        setRowsState(prevState =>
            prevState.map((row, i) => (i === index ? { ...row, [field]: value } : row))
        );
    };

    const handleButtonGroupChange = (index: number, value: 'ON' | 'OFF' | 'NEW') => {
        handleRowChange(index, 'onOff', value);
    };

    const toggleAllSwitches = (value: 'ON' | 'OFF') => {
        setAllOnOff(value);
        setRowsState(prevState =>
            prevState.map(row => ({
                ...row,
                onOff: value
            }))
        );
    };

    const formatDataForSave = () => {
        return rowsState.map(row => ({
            id: row.id,
            workerId: row.workerId || '',
            targetPool: targetPoolList[row.targetPool ?? 0] || 'NONE',
            hashVendor: hashVendorList[row.hashVendor ?? 0]?.name || 'NONE',
            state: row.targetPool=== 1 ? "OFF" : (row.onOff === 'NEW' ? 'NEW' : row.onOff)
        }));
    };

    const handleSave = () => {
        setLoading(true);
        const formattedData = formatDataForSave();

        axios.post(API_PATH + '/machines/update', formattedData, getAxiosConfig())
            .then(response => {
                const { data } = response;
                const allSuccess = data.every((item: { status: boolean; }) => item.status === true);

                if (allSuccess) {
                    setSuccess(true);
                    setAlertMessage('Data saved successfully!');
                    setTimeout(() => {
                        setRowsState([]);
                        setAllHashVendor(null);
                        setAllTargetPool(null);
                        setManualWorkerId(null);
                        setAllOnOff(null);
                        onClose();
                        setTrigger(!trigger)
                    }, 2000);
                    
                } else {
                    const failureReasons = data
                        .filter((item: { status: any; }) => !item.status)
                        .map((item: { reason: any; }) => item.reason)
                        .join(', ');

                    setSuccess(false);
                    setAlertMessage(`Failed to save some data: ${failureReasons}`);
                }
            })
            .catch(error => {
                console.error('Error saving data:', error);
                setSuccess(false);
                setAlertMessage('An error occurred while saving data.');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleCloseAlert = () => {
        setSuccess(false);
    };

    const handleCloseModal = () => {
        onClose()
        setAllHashVendor(null)
        setAllTargetPool(null)
    }

    return (
        <Dialog open={open} onClose={handleCloseModal} fullWidth maxWidth="lg">
            <DialogTitle>Configure Machines</DialogTitle>
            <DialogContent>
                <Table style={{ width: '100%' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell style={{ width: '10%' }}><strong>ID</strong></TableCell>
                            <TableCell style={{ width: '10%' }}><strong>Model</strong></TableCell>
                            <TableCell style={{ width: '20%' }}><strong>Hash Vendor</strong></TableCell>
                            <TableCell style={{ width: '20%' ,textAlign:'center'}}><strong>Target Pool</strong></TableCell>
                            <TableCell style={{ width: '20%' }}><strong>Worker ID</strong></TableCell>
                            <TableCell style={{ width: '10%' }}><strong>On/Off</strong></TableCell>
                        </TableRow>
                        {data.length > 1 &&
                            <TableRow>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell>
                                    <SingleSelectChip2
                                        dataList={hashVendorList.map((vendor, index) => ({ id: index, name: vendor.name }))}
                                        selectedId={allHashVendor ?? null}
                                        setSelectedId={(id) => handleAllChange('hashVendor', id)}
                                        name="Hash Vendor"
                                    />
                                </TableCell>
                                <TableCell>
                                    {/* <SingleSelectChip2
                                        dataList={targetPoolList.map((pool, index) => ({ id: index, name: pool }))}
                                        selectedId={allTargetPool ?? null}
                                        setSelectedId={(id) => handleAllChange('targetPool', id)}
                                        name="Target Pool"
                                    /> */}
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        label="Worker ID"
                                        size='small'
                                        value={manualWorkerId || ''}
                                        onChange={(e) => {
                                            const newId = e.target.value;
                                            setManualWorkerId(newId);
                                            autofillWorkerIds(newId);
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <ButtonGroup variant="outlined" aria-label="On/Off button group">
                                        <Button
                                            onClick={() => toggleAllSwitches('ON')}
                                            variant={allOnOff === 'ON' ? 'contained' : 'outlined'}
                                            disabled={rowsState.some(t => t.targetPool === 1)}
                                        >
                                            On
                                        </Button>
                                        <Button
                                            onClick={() => toggleAllSwitches('OFF')}
                                            variant={allOnOff === 'OFF' ? 'contained' : 'outlined'}
                                            disabled={rowsState.some(t => t.targetPool === 1)}
                                        >
                                            Off
                                        </Button>
                                        <Button disabled>New</Button>
                                    </ButtonGroup>
                                </TableCell>
                            </TableRow>
                        }
                    </TableHead>
                    <TableBody>
                        {rowsState.map((row, index) => (
                            <TableRow key={row.id}>
                                <TableCell style={{ width: '10%' }}>{row.id}</TableCell>
                                <TableCell style={{ width: '10%' }}>{row.modelName}</TableCell>
                                <TableCell style={{ width: '20%' }}>
                                    <SingleSelectChip2
                                        dataList={hashVendorList.map((vendor, idx) => ({ id: idx, name: vendor.name }))}
                                        selectedId={row.hashVendor ?? null}
                                        setSelectedId={(id) => handleRowChange(index, 'hashVendor', id)}
                                        name="Hash Vendor"
                                    />
                                </TableCell>
                                <TableCell style={{ width: '20%', textAlign: 'center' ,color:"green",fontWeight:'bold'}}>
                                    {/* <SingleSelectChip2
                                        dataList={targetPoolList.map((pool, idx) => ({ id: idx, name: pool }))}
                                        selectedId={row.targetPool ?? null}
                                        setSelectedId={(id) => handleRowChange(index, 'targetPool', id)}
                                        name="Target Pool"
                                    /> */}
                                    {row.targetPool === 2 ? 'SEG POOL' : row.targetPool === 1 ? 'F2POOL' : null}
                                </TableCell>
                                <TableCell style={{ width: '20%' }}>
                                    <TextField
                                        label="Worker ID"
                                        size='small'
                                        value={row.workerId || ''}
                                        onChange={(e) => handleRowChange(index, 'workerId', e.target.value)}
                                    />
                                </TableCell>
                                <TableCell style={{ width: '10%' }}>
                                    <MachineOnOffButtonGroup row={row} index={index} handleButtonGroupChange={handleButtonGroupChange} disabled={row.targetPool !== 2}/>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
                <Button onClick={handleSave} color="primary" disabled={loading}>
                    {loading ? <CircularProgress size={24} /> : 'Save'}
                </Button>
            </DialogActions>
            <Snackbar open={!!alertMessage} autoHideDuration={6000} onClose={handleCloseAlert}>
                <Alert onClose={handleCloseAlert} severity={success ? "success" : "error"}>
                    {alertMessage}
                </Alert>
            </Snackbar>
        </Dialog>
    );
};

export default ConfigModal;
