
export interface TransactionData {
    id: string;
    paymentMode: string;
    amount: string;
    networkTransactionId: string | null;
    transactionStatus: string;
    createdTime: string;
    created: string;
    transactionTime: string ;
    rawError: string | null;
    targetWallet: string | null;
    failed: boolean;
    rewardTables: RewardTable[];
    // date: string;
    // payoutDate: string;
    // coin: string;
    // rewardTableAmount: number;
    // valueInBtc: number;
    hashRateInTh: number;
    // payAsBtc: boolean;
    // payoutStatus: boolean;
    // transactionId: string | null;
    // rewardStatus: string;
    userName: string;
    dummy: boolean;
    // email: string;
}

interface Account {
    userName: string;
}

interface RewardTable {
    account?: Account;
    coin: string;
}



function toTransactionData(x: any): TransactionData {
    const firstRewardTable = x.rewardTables[0] || {};

    return {
        id: x.id,
        dummy: x.dummy,
        paymentMode: x.paymentMode,
        amount: x.amount.toString(),
        networkTransactionId: x.networkTransactionId,
        transactionStatus: x.transactionStatus,
        createdTime: x.createdTime,
        created: dateStyled(x.createdTime),
        transactionTime: x.transactionTime,
        rawError: x.rawError,
        targetWallet: x.wallet ? x.wallet.wallet : null,
        failed: x.failed,
        hashRateInTh: firstRewardTable.hashRateInTh || 0,
        userName: firstRewardTable.account ? firstRewardTable.account.userName : "",
        rewardTables: x.rewardTables
    };
}


export function processTransactionList(data: any[]): TransactionData[] {
    return data.map(toTransactionData);
}

function dateStyled(createdTime: any): string {
    if (createdTime == null || createdTime == undefined) return "";
    let date = new Date(createdTime);
    return String(date.getDate()).padStart(2, '0') + "/" + String(date.getMonth() + 1).padStart(2, '0') + "/" + date.getFullYear();
}

export interface BalanceData {
    [coin: string]: {
        available: number;
        pending: number;
        total: number;
    };
}
