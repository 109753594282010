import React from 'react';
import {
    Modal,
    Box,
    Typography,
    Grid,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    TablePagination,
    Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import NotificationLoader from './NotificationLoader';

interface Notification {
    message: string;
    infos: string[];
}

interface Pagination {
    totalItemCount: number;
}

interface NotificationModalProps {
    open: boolean;
    onClose: () => void;
    notifications: Notification[];
    pagination: Pagination;
    loading: boolean;
    rowsPerPage: number;
    page: number;
    handleChangePage: (event: unknown, newPage: number) => void;
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleMarkAsRead: () => void;
}

const NotificationModal: React.FC<NotificationModalProps> = ({
    open,
    onClose,
    notifications,
    pagination,
    loading,
    rowsPerPage,
    page,
    handleChangePage,
    handleChangeRowsPerPage,
    handleMarkAsRead
}) => {
    return (
        <Modal open={open} onClose={onClose} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Box sx={{ width: '70%', minHeight: '70vh', maxHeight: '70vh', bgcolor: 'background.paper', p: 4, position: 'relative', overflowY: 'auto' }}>
                <Typography variant="h6" sx={{ mb: 2 }}>
                    Notifications
                </Typography>
                {loading && <NotificationLoader />}
                {!loading && (
                    <div>
                        {notifications.map((item, index) => (
                            <Grid container sx={{ width: '100%' }} key={index}>
                                <Grid item xs={12}>
                                    <Accordion sx={{ my: 1 }}>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`panel-content-${index}`}
                                            id={`panel-header-${index}`}
                                        >
                                            <Typography>{item.message}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {item.infos.map((info, i) => (
                                                <Typography key={i}>{info}</Typography>
                                            ))}
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        ))}
                    </div>
                )}
                <Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mt: 'auto' }}>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={pagination.totalItemCount}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', mt: 'auto' }}>
                    <Button onClick={onClose} variant="contained" sx={{ m: 2 }}>
                        Close
                    </Button>
                    <Button onClick={handleMarkAsRead} variant="contained" sx={{ m: 2 }}>
                        Mark As Read
                    </Button>
                </Box>
            </Box>
        </Modal>
    );
};

export default NotificationModal;
