import { Box, Grid, TextField, Button, Table, TableHead, TableRow, TableCell, TableBody, Paper, Menu, MenuItem, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Checkbox, TablePagination } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { getAxiosConfig } from '../../../helpers/AuthHelper';
import axios from 'axios';
import { API_PATH, appContext } from '../../../App';
import { MachineInfo, MachineModelInfo, CustomerGroup, Pageable, SelectedDataItem } from './types';
import MultipleSelectChip from '../GlobalComponents/MultiSelectChip';
import SingleSelectStringChip from '../GlobalComponents/SingleSelectStringChip';
import ConfigModal from './ConfigModal';
import TableLoader from '../GlobalComponents/TableLoader';
import SettingsIcon from '@mui/icons-material/Settings';
import HistoryIcon from '@mui/icons-material/History';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import HistoryModal from '../GlobalComponents/HistoryModal';
import SearchIcon from '@mui/icons-material/Search'
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Tooltip from '@mui/material/Tooltip';
import { Invoice, Machine } from '../Invoices/types';
import { InfoOutlined, Description, Download } from '@mui/icons-material';
import InvoiceModal from '../Invoices/InvoiceModal';
import Cookies from 'js-cookie';
import { EmitterHash } from '../Monitor/types';


interface Props { }

function MachineViewList(props: Props) {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [customerIds, setCustomerIds] = useState<number[]>([]);
    const [modelIds, setModelIds] = useState<number[]>([]);
    const [openConfirmation, setOpenConfirmation] = useState<boolean>(false);
    const [modelDataList, setModelDataList] = useState<SelectedDataItem[]>([]);
    const [customerDataList, setCustomerDataList] = useState<SelectedDataItem[]>([]);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(300);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [modalData, setModalData] = useState<MachineInfo[]>([]);
    const [paginatedDataList, setPaginatedDataList] = useState<MachineInfo[]>([]);
    const [totalItemCount, setTotalItemCount] = useState<number>(0);
    const [pageableData, setPageableData] = useState<Pageable | null>(null);
    const [statusList, setStatusList] = useState<string[]>([]);
    const [selectedStatus, setSelectedStatus] = React.useState<string | null>(null);
    const [selectedRows, setSelectedRows] = useState<MachineInfo[]>([]);
    const [trigger, setTrigger] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)
    const [selectedMachine, setSelectedMachine] = useState<MachineInfo>();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [historyModalOpen, setHistoryModalOpen] = useState<boolean>(false);
    const [machineList, setMachineList] = useState<Machine[]>([]);
    const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);
    const [trigerList, setTriggerList] = useState<boolean>(true)
    const [hashEmitterList, setHashEmitterList] = useState<string[]>([])
    const { authRole } = React.useContext(appContext);


    const handleCloseModal = () => {
        setOpenModal(false);
        setModalData([]);
    };

    useEffect(() => {
        fetchMachineList();
        fetchCustomerList();
        fetchStateList();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const hashList = await fetchEmitterList();
            if (hashList.length > 0) {
                fetchTableData(hashList);
            }
        };
        fetchData();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            const hashList = hashEmitterList ? hashEmitterList : await fetchEmitterList();
            if (hashList.length > 0) {
                fetchTableData(hashList);
            }
        };
        fetchData();
    }, [trigger, page, rowsPerPage, hashEmitterList]);

    const fetchEmitterList = async () => {
        setLoading(true);
        try {
            const response = await axios.get(API_PATH + "/monitor/emitterhash", getAxiosConfig());
            const emitterHashData: EmitterHash[] = response.data;
            const allWorkers = emitterHashData.flatMap((item) => item.workerList || []);
            setHashEmitterList(allWorkers);
            return allWorkers;
        } catch (error) {
            console.error("Error fetching emitter hash list:", error);
            return [];
        }
    };

    const fetchTableData = async (hashList: string[]) => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${API_PATH}/machines/list?key=${searchTerm.replace(/\+/g, "%2B")}&customer=${customerIds.join(',')}&model=${modelIds.join(',')}&state=${selectedStatus}&page=${page}&limit=${rowsPerPage}`,
                getAxiosConfig()
            );

            const data = response.data.content;
            const paginatedData = data.map((item: any) => ({
                id: item.id,
                workerId: item.workerId,
                invoice: item.invoice,
                invoiceId: item.invoice.id,
                reference: item.invoice.reference,
                customerGroupName: item.customer.customerGroupName,
                modelName: item.machineModel.modelName,
                targetPool: item.targetPool,
                hashVendor: item.hashVendor,
                machineStatus: item.machineStatus,
                poolStatus: item.poolStatus,
                data: item,
                hostingCharge: item.invoiceItem.hostingCharge,
                onStatus: hashList.includes(item.workerId),
            }));

            setPaginatedDataList(paginatedData);
            setTotalItemCount(response.data.totalElements);
            setPageableData({
                pageNumber: response.data.pageable.pageNumber,
                pageSize: response.data.pageable.pageSize,
            });
            setSelectedRows([]);
        } catch (error) {
            console.error("Error fetching machine list:", error);
        } finally {
            setLoading(false);
        }
    };

    const fetchMachineList = () => {
        axios.get(API_PATH + '/machine_model/list', getAxiosConfig())
            .then(response => {
                const DataList: SelectedDataItem[] = response.data.map((item: MachineModelInfo) => ({
                    id: item.id,
                    name: item.modelName
                }));
                setModelDataList(DataList);
                setMachineList(response.data);
            })
            .catch(error => {
                console.error("Error fetching machine list:", error);
            });
    };

    const fetchCustomerList = () => {
        axios.get(API_PATH + '/customer/list?orderBy=none', getAxiosConfig())
            .then(response => {
                const DataList: SelectedDataItem[] = response.data.map((item: CustomerGroup) => ({
                    id: item.id,
                    name: item.customerGroupName
                }));
                setCustomerDataList(DataList);
            })
            .catch(error => {
                console.error("Error fetching customer list:", error);
            });
    };

    const fetchStateList = () => {
        axios.get(API_PATH + '/machines/states', getAxiosConfig())
            .then(response => {
                setStatusList(response.data);
            })
            .catch(error => {
                console.error("Error fetching states list:", error);
            });
    };

    const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchButtonClick = () => {
        // fetchTableData();
        const fetchData = async () => {
            const hashList = await fetchEmitterList();
            if (hashList.length > 0) {
                fetchTableData(hashList);
            }
        };
        fetchData()
    };


    const handleCloseConfirmation = (confirm: boolean) => {
        setOpenConfirmation(false);
    };

    const handlePageChange = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleSelectRow = (id: number) => {
        setSelectedRows((prev) => {
            const isSelected = prev.some((row) => row.id === id);
            if (isSelected) {
                return prev.filter((row) => row.id !== id);
            } else {
                const selectedRow = paginatedDataList.find((row) => row.id === id);
                return selectedRow ? [...prev, selectedRow] : prev;
            }
        });
    };
    const handleCommonConfig = () => {
        if (selectedRows.length > 0) {
            setModalData(selectedRows);
            setOpenModal(true);
        }
    };

    const handleSelectAll = () => {
        if (selectedRows.length === paginatedDataList.length) {
            setSelectedRows([]);
        } else {
            setSelectedRows(paginatedDataList);
        }
    };

    const handleMenuClick = (event: React.MouseEvent<HTMLElement>, machine: MachineInfo) => {
        setAnchorEl(event.currentTarget);
        setSelectedMachine(machine);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleHistoryOpen = () => {
        setHistoryModalOpen(true);
        handleMenuClose();
    };

    const handleHistoryClose = () => setHistoryModalOpen(false);

    function getPoolStatus(poolStatus: string): React.ReactNode {
        if (poolStatus === null || poolStatus.trim().length === 0) return <></>;
        if (poolStatus === "ACTIVE") return <Tooltip title="Active in pool">
            <PowerIcon fontSize='small' sx={{ color: "#0F0" }} />
        </Tooltip>;
        if (poolStatus === "INACTIVE") return <Tooltip title="Inactive in pool">
            <PowerOffIcon fontSize='small' sx={{ color: "#F00" }} />
        </Tooltip>;
        return <Tooltip title="Missing in pool">
            <ReportProblemIcon fontSize='small' sx={{ color: '#ff5722' }} />
        </Tooltip>;
    }

    const handleRowClick = (customerGroupName: string, invoiceId: number) => {
        axios.get(`${API_PATH}/invoice/list?key=${customerGroupName}&page=${0}&limit=${100}`, getAxiosConfig())
            .then(response => {
                response.data.content && setSelectedInvoice(response.data.content.find((i: Invoice) => i.id === invoiceId));
            })
            .catch(error => {
                console.error("Error fetching invoice list:", error);
            })
        handleMenuClose();
    };

    const handleCloseInvoiceModal = () => {
        setSelectedInvoice(null);
    }

    const handleDownloadCSV = () => {
        axios.get(`${API_PATH}/machines/list?key=${searchTerm.replace(/\+/g, "%2B")}&customer=${customerIds.join(',')}&model=${modelIds.join(',')}&state=${selectedStatus}&page=${page}&limit=${totalItemCount}`, getAxiosConfig())
            .then(response => {
                const paginatedDataList = response?.data?.content || [];
                const headers = [
                    'ID',
                    'Worker ID',
                    'Customer Name',
                    'Model Name',
                    'Target Pool',
                    'Hash Vendor',
                    'Hosting Charge',
                    'Pool Status',
                    'State',
                ];

                const csvRows = [
                    headers.join(','),
                    ...paginatedDataList.map((item: any) => [
                        item?.id,
                        item?.workerId,
                        item?.customer?.customerGroupName,
                        item?.machineModel?.modelName,
                        item?.targetPool,
                        item?.hashVendor,
                        item?.invoiceItem?.hostingCharge,
                        item?.targetPool === "F2POOL" ?
                            (hashEmitterList.includes(item.workerId) ? "ACTIVE" : "INACTIVE") :
                            item?.poolStatus,
                        item?.targetPool === "F2POOL" ?
                            (hashEmitterList.includes(item.workerId) ? "ON" : "OFF") :
                            item?.machineStatus,
                    ].join(',')),
                ];
                const csvString = csvRows.join('\n');
                const blob = new Blob([csvString], { type: 'text/csv' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = 'machine_report.csv';
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
                window.URL.revokeObjectURL(url);
            })
            .catch(error => {
                console.error('Error fetching data for CSV:', error);
            });
    };


    return (
        <Box sx={{ flexGrow: 1, p: 2, position: 'relative', height: 'calc(100vh - 64px)' }}>
            <Grid container spacing={0}>
                <Grid item xs={9} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <TextField
                        label="Search"
                        value={searchTerm}
                        onChange={handleSearch}
                        variant="outlined"
                        size="small"
                        sx={{ width: '98%' }}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSearchButtonClick}
                        fullWidth
                        startIcon={<SearchIcon sx={{ fontSize: 18, marginRight: 1 }} />}
                    >
                        Search
                    </Button>
                </Grid>
            </Grid>
            <Grid container spacing={0}>
                <Grid item xs={12} sm={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <MultipleSelectChip
                        dataList={customerDataList}
                        selectedIds={customerIds}
                        setSelectedIds={setCustomerIds}
                        name="Customer"
                    />
                </Grid>
                <Grid item xs={12} sm={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <MultipleSelectChip
                        dataList={modelDataList}
                        selectedIds={modelIds}
                        setSelectedIds={setModelIds}
                        name="Model"
                    />
                </Grid>
                <Grid item xs={12} sm={3} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <SingleSelectStringChip
                        dataList={statusList}
                        selectedValue={selectedStatus}
                        setSelectedValue={setSelectedStatus}
                        name="Status"
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={3}
                    sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 2 }}
                >
                    <Button
                        variant="contained"
                        size="small"
                        onClick={handleDownloadCSV}
                        disabled={hashEmitterList.length === 0}
                    >
                        <Download />
                    </Button>

                    {authRole === 'ADMIN' && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCommonConfig}
                            disabled={selectedRows.length === 0}
                            startIcon={<SettingsIcon />}
                            fullWidth
                        >
                            Configure Selected
                        </Button>
                    )}
                </Grid>
            </Grid>

            <Paper elevation={3} sx={{ padding: 3, marginTop: 2 }}>
                {loading ? (
                    <TableLoader />
                ) : (
                    <Table style={{ width: '100%' }}>
                        <TableHead>
                            <TableRow sx={{ backgroundColor: (theme) => theme.palette.mode === 'dark' ? '#333' : '#f5f5f5', color: (theme) => theme.palette.mode === 'dark' ? '#fff' : '#000', padding: '6px' }}>
                                {authRole === 'ADMIN' &&
                                    <TableCell style={{ width: '5%' }}>
                                        <Checkbox
                                            checked={selectedRows.length === paginatedDataList.length}
                                            onChange={() => handleSelectAll()}
                                        />
                                    </TableCell>
                                }
                                <TableCell>ID</TableCell>
                                <TableCell>&nbsp;</TableCell>
                                <TableCell>Worker ID</TableCell>
                                <TableCell>Customer Name</TableCell>
                                <TableCell>Model Name</TableCell>
                                <TableCell>Target Pool</TableCell>
                                <TableCell>Hash Vendor</TableCell>
                                <TableCell>Hosting Charge</TableCell>
                                <TableCell>Pool Status</TableCell>
                                <TableCell>State</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {paginatedDataList.map((item) => (
                                <TableRow key={item.id}>
                                    {authRole === 'ADMIN' && (
                                        <TableCell style={{ width: '5%' }}>
                                            <Checkbox
                                                checked={selectedRows.some((row) => row.id === item.id)}
                                                onChange={() => handleSelectRow(item.id)}
                                            />
                                        </TableCell>
                                    )}
                                    <TableCell>{item.id}</TableCell>
                                    <TableCell>
                                        {item.targetPool === "F2POOL" ? (
                                            <span
                                                style={{
                                                    color: item?.onStatus ? "#66bb6a" : "#e57373",
                                                    fontWeight: "bold",
                                                    fontSize: "0.8rem"
                                                }}
                                            >
                                                {item?.onStatus ? "" : getPoolStatus(item?.poolStatus)}
                                            </span>
                                        ) : (
                                            <span>{getPoolStatus(item?.poolStatus)}</span>
                                        )}
                                    </TableCell>
                                    <TableCell>{item.workerId}</TableCell>
                                    <TableCell>{item.customerGroupName}</TableCell>
                                    <TableCell>
                                        <Tooltip
                                            placement="right-start"
                                            title={
                                                (() => {
                                                    const matchedMachine = machineList.find(machine => machine.modelName === item.modelName);
                                                    return matchedMachine ? (
                                                        <>
                                                            Model: {matchedMachine.modelName} <br />
                                                            Coin: {matchedMachine.coin.coin} <br />
                                                            Algorithm: {matchedMachine.coin.algorithm} <br />
                                                            Hash Rate: {matchedMachine.hashRate} {matchedMachine.hashUnit} <br />
                                                            Power: {matchedMachine.power}W <br />
                                                            Price: ${matchedMachine.price} <br />
                                                            Vendor: {matchedMachine.vendor.vendorName}
                                                        </>
                                                    ) : (
                                                        'No details available'
                                                    );
                                                })()
                                            }
                                            arrow
                                        >
                                            <span style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                                {item.modelName}
                                                <InfoOutlined fontSize="small" style={{ marginLeft: '4px', color: '#1976D2' }} />
                                            </span>
                                        </Tooltip>
                                    </TableCell>

                                    <TableCell>{item.targetPool}</TableCell>

                                    <TableCell>{item.hashVendor}</TableCell>
                                    <TableCell>{item.hostingCharge}</TableCell>
                                    <TableCell>
                                        {item.targetPool === "F2POOL" ? (
                                            <span style={{
                                                color: item?.onStatus ? "#66bb6a" : "#e57373",
                                                fontWeight: "bold",
                                                fontSize: "0.8rem"
                                            }}>
                                                {item?.onStatus ? "ACTIVE" : "INACTIVE"}
                                            </span>
                                        ) : (
                                            <span style={{
                                                color: item.poolStatus === "ACTIVE" ? "#66bb6a" : "#e57373",
                                                fontWeight: "bold",
                                                fontSize: "0.8rem"
                                            }}>
                                                {item.poolStatus}
                                            </span>
                                        )}
                                    </TableCell>

                                    <TableCell>
                                        {item.targetPool === "F2POOL" ? (
                                            <span style={{ color: item?.onStatus ? "green" : "red", fontWeight: "bold" }}>
                                                {item?.onStatus ? "ON" : "OFF"}
                                            </span>
                                        ) : (
                                            <span style={{ color: item.machineStatus === "ON" ? "green" : "red", fontWeight: "bold" }}>
                                                {item.machineStatus}
                                            </span>
                                        )}
                                    </TableCell>

                                    {authRole === 'ADMIN' &&
                                        <TableCell>
                                            <IconButton
                                                onClick={(event) => handleMenuClick(event, item)}
                                            >
                                                <MoreVertIcon />
                                            </IconButton>
                                            <Menu
                                                anchorEl={anchorEl}
                                                open={Boolean(anchorEl) && selectedMachine === item}
                                                onClose={handleMenuClose}
                                            >
                                                <MenuItem onClick={handleHistoryOpen}>
                                                    <HistoryIcon sx={{ mr: 1 }} /> History
                                                </MenuItem>
                                                <MenuItem onClick={() => handleRowClick(item.customerGroupName, item.invoiceId)}>
                                                    <Description sx={{ mr: 1 }} />Invoice
                                                </MenuItem>
                                            </Menu>
                                        </TableCell>
                                    }
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                )}

                <TablePagination
                    rowsPerPageOptions={[300, 500, 1000, 1500]}
                    component="div"
                    count={totalItemCount}
                    rowsPerPage={pageableData?.pageSize || 300}
                    page={pageableData?.pageNumber || 0}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleRowsPerPageChange}
                />
            </Paper>
            <ConfigModal
                open={openModal}
                onClose={handleCloseModal}
                data={modalData}
                trigger={trigger}
                setTrigger={setTrigger}
            />
            <HistoryModal
                open={historyModalOpen}
                onClose={handleHistoryClose}
                selectedId={selectedMachine?.id || null}
                entity={'Machines'}
            />
            <InvoiceModal
                open={Boolean(selectedInvoice)}
                isAdding={false}
                onClose={handleCloseInvoiceModal}
                selectedInvoice={selectedInvoice}
                setSelectedInvoice={setSelectedInvoice}
                setTriggerList={setTriggerList}
                trigerList={trigerList}
            />
            <Dialog
                open={openConfirmation}
                onClose={() => handleCloseConfirmation(false)}
            >
                <DialogTitle>Confirm Status Change</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to change the status?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => handleCloseConfirmation(false)}>Cancel</Button>
                    <Button onClick={() => handleCloseConfirmation(true)} color="primary">
                        Confirm
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
}

export default MachineViewList;